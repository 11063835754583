import React from 'react';
import OrderNumber from 'components/Quote/QuoteReusableComp/OrderNumber';
import Head from "components/Quote/Quotelayout/Head";


function checkout() {
    return (
        <>
        <Head/>
            <OrderNumber/>
        </>
    )
}

export default checkout
