import React, { useContext, useEffect } from "react";
import { navigate } from "gatsby";
import DataProvider from "Context/DataContext";
import moment from "moment";
import axios from "axios";
import { API_ROOT } from "utils/http";

function OrderNumber() {
  const { thirdStepData, quoteId, setQuoteId, setThirdStepData } = useContext(DataProvider);

  useEffect(() => {
    function handleBack() {
      window.history.go(1);
    }
    window.addEventListener("popstate", handleBack);

    const getData = localStorage.getItem("order_id");

    // console.log(getData);
    const saveQuoteId = async () => {
      await axios.get(`${API_ROOT}/api/v1/get-order-info?order_id=${getData.replace("#", "")}`).then((res) => {
        setThirdStepData(res?.data);
        // setQuoteId(getData);
      });
    };

    if (getData) {
      saveQuoteId();
    } else {
      return navigate("/quote");
    }
    return () => {
      window.removeEventListener("popstate", handleBack);
    };
  }, [quoteId]);

  const handleNavigate = () => {
    navigate("/quote/order-summary");
  };
  const style = {
    background: "transparent linear-gradient(180deg, #F1F6F8 0%, #F9FAFB 61%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  };
  return (
    <div className="flex items-center justify-center flex-col" style={style}>
      <div className="flex items-center justify-center flex-col max-w-[742px] w-11/12 sm:w-full border bg-white border-quotetext-100 border-t-0 pb-10 mt-10">
        <div className="bg-quotetext-600 h-6 w-full"></div>
        <p className="text-xl font-secondary w-11/12 pb-6 pt-8 text-center px-3 font-medium border-b border-quotetext-100">
        Thank you! We have received your order. Someone will reach out to you for the next steps. Your information appears below.       </p>
        <div className="w-11/12 md:px-12 py-7 border-b border-quotetext-100">
          <p className="grid grid-cols-2 text-xl font-opensans font-bold mb-3">
            <span>Order Number:</span>
            <span>{thirdStepData?.data?.quote?.order_number ? thirdStepData?.data?.quote?.order_number : <div class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-10/12"></div>}</span>
          </p>
          <p className="grid grid-cols-2 text-xl font-opensans font-bold mb-3">
            <span>Delivery Date:</span>
            <span>
              {thirdStepData?.data?.quote?.delivery_date ? (
                moment(thirdStepData?.data?.quote?.delivery_date).format("MMMM DD, YYYY")
              ) : (
                <div class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-10/12"></div>
              )}
            </span>
          </p>
          <p className="grid grid-cols-2 text-xl font-opensans font-bold">
            <span>Total Order:</span>
            <span>
              {thirdStepData?.data?.quote?.final_price_after_tax ? (
                thirdStepData?.data?.quote?.final_price_after_tax
              ) : (
                <div class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-10/12"></div>
              )}
            </span>
          </p>
        </div>
        <p className="text-2xl font-semibold font-secondary mt-8 pt-2">Want to pay now?</p>
        <p className="text-base text-quotetext-500 py-6 font-opensans text-center">If you want you can pay now or within 5 days of translation delivery.</p>
        <button className="bg-orange text-white w-[215px] h-[59px] rounded-full font-arial font-bold text-xl" onClick={handleNavigate}>
          Pay now
        </button>
      </div>
      <p className="text-quotetext-500 text-lg font-opensans mt-10 mb-16 text-center">
        Need help with your order? Contact us at <span className="font-semibold"> support@tomedes.com </span>
      </p>
    </div>
  );
}
export default OrderNumber;
